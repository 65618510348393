import React, { Component, createContext } from 'react';
const { Provider, Consumer } = createContext();

class LoginContextProvider extends Component {
    constructor() {
        super();
        this.state = {
            isLoggedIn: this.getInitialStatus()
        };
    }

    getInitialStatus() {
        const initialStatus = localStorage.getItem("isLoggedIn")
        if (initialStatus === null) return false
        else return localStorage.getItem("isLoggedIn")

    }
    changeLoginStatus = (setLocalStorage) => {
        this.setState(prevState => {
            let newStatus = prevState.isLoggedIn === true ? false : true
            this.setLocalStorage(newStatus);
            return {
                isLoggedIn: newStatus
            };
        });

    }
    setLocalStorage(newStatus) {
        localStorage.setItem("isLoggedIn", newStatus);
    }
    render() {
        return (
            <Provider value={{ isLoggedIn: this.state.isLoggedIn, changeLoginStatus: this.changeLoginStatus }}>
                {this.props.children}
            </Provider>
        )
    }
}
export { LoginContextProvider, Consumer as LoginContextConsumer }