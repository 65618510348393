import React from 'react'


import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));


export default function TagUIStep(props) {

    const classes = useStyles();

    function handleClick() {
        try {
            window.clipboardData.setData("Text", props.step.toString())
        }
        catch (err) {
            try {
                navigator.clipboard.writeText(props.step.toString())
            } catch (er) {
                console.log(er)
            }
            console.log(err)
        }
    }
    const text = props.step;
    const newText = props.step.split('\n').map(str => {
        let space = "";
        for (let i = 0; i < str.length; i++) {
            if (str.charAt(i) === " ") space = space + "\u00a0";
            else break;
        }
        return space + str + "\n";
    });
    return (
        <Paper style={{
            backgroundColor: "#FCF3E3",
        }} className={classes.paper}>
            <Grid container style={{ justifyContent: 'center', display: 'flex', }}>
                <Grid container item xs={11} style={{
                    color: "#757574"
                }} >

                    <strong>{props.flowFile}</strong>

                </Grid>

                <Grid container item xs={1} justify="flex-end">
                    <Button style={{
                        color: "#757574",
                        minHeight: 0,
                        minWidth: 0,
                        maxHeight: 40
                    }}
                        onClick={handleClick}>
                        <FileCopyIcon fontSize="small" />
                    </Button>
                </Grid>
                <Grid container item xs={11} alignItems="center" justify="left">
                    <div className="display-linebreak" style={{
                        color: "#757574"
                    }}>
                        {newText}
                    </div>
                </Grid>
                <Grid container item xs={1} alignItems="center" justify="left">

                </Grid>
            </Grid>
        </Paper>
    )
}