import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import FileCopyIcon from '@material-ui/icons/FileCopy';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));


export default function FinalCode(props) {
    const classes = useStyles();


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function handleClick(item) {
        try {
            window.clipboardData.setData("Text", item.code.toString())
        }
        catch (err) {
            try {
                navigator.clipboard.writeText(item.code.toString())
            } catch (er) {
                console.log(er)
            }
            console.log(err)
        }
    }

    const styles = theme => ({
        label: {
            color: '#FFF000'
        },
        indicator: {
            backgroundColor: '#FFF'
        }
    });

    const count = props.finalCodeArr.length;
    return (
        <div>
            <div style={{ backgroundColor: '#f9f9f9', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                        //    indicatorColor="primary"
                            textColor="#757574"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"

                            TabIndicatorProps={{ style: { background: '#757574' } }}
                            classes={{ indicator: classes.indicator }}
                        >

                            {props.finalCodeArr.map(function (item, i) {
                                return <Tab label={item.fileName} {...a11yProps(i)} />
                            })}

                            {/* // <Tab label="main.tag" {...a11yProps(0)} />
                            // <Tab label="login.tag" {...a11yProps(1)} />
                            // <Tab label="newjobposting.tag" {...a11yProps(2)} />
                            // <Tab label="viewapplicants.tag" {...a11yProps(3)} />
                            // <Tab label="processapplicants.tag" {...a11yProps(4)} /> */}


                        </Tabs>
                    </AppBar>

                    {props.finalCodeArr.map(function (item, i) {
                        const newText = item.code.split('\n').map(str => {
                            let space = "";
                            for (let i = 0; i < str.length; i++) {
                                if (str.charAt(i) === " ") space = space + "\u00a0";
                                else break;
                            }
                            return space + str + "\n";
                        });
                        return (<TabPanel value={value} index={i} >
                            <Grid container style={{ justifyContent: 'center', display: 'flex', }}>
                                <Grid container item xs={11} alignItems="center" justify="left">
                                    <div className="display-linebreak" style= {{ color: '#757574'} } >
                                        {newText}
                                    </div>
                                </Grid>
                                <Grid container item xs={1} justify="flex-end">
                                    <Button style={{
                                        color: "#757574",
                                        minHeight: 0,
                                        minWidth: 0,
                                        maxHeight: 40
                                    }}
                                        onClick={() => handleClick(item)}
                                    >
                                        <FileCopyIcon fontSize="small" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>)
                    })}

                    {/* <TabPanel value={value} index={1}>Item Two </TabPanel>
                    <TabPanel value={value} index={2}>
                        Item Three
                    </TabPanel> */}

                </div>



            </div>

            {/* <div>
                {props.finalCodeArr.map(student => `<li> ${student.fileName} </li>`)}
            </div> */}

        </div >
    );
}
