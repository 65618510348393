import React, { useState, useEffect } from 'react';

//import material-ui table
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import Decision from './Decision.js';
//import model
import jobsData from '../models/jobsData.js';
import applicantsData from '../models/applicantsData.js';

//import react-router-dom
import { Link, Redirect, useParams } from 'react-router-dom';


import { jsPDF } from "jspdf";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});


function JobDetails(props) {
    const { jobId } = useParams();
    const thisJob = jobsData.find(job => job.id.toString() === jobId)
    const thisApplicants = applicantsData.filter(applicant => applicant.jobId.toString() === jobId)


    const initialAllApplicants = localStorage.getItem("allApplicants") === null ? "[]" : localStorage.getItem("allApplicants");
    const [allApplicants, setAllApplicants] = useState(initialAllApplicants);
    const applicantsDataString = JSON.stringify(applicantsData);




    const classes = useStyles();

    useEffect(() => {
        localStorage.setItem("allApplicants", allApplicants);
    }, [allApplicants]);

    return (
        <div>
            {applicantsDataString === allApplicants ? <Redirect to="/congratulations" /> : <div></div>}
            <Button component={Link} to="/jobs" name="backToList" id="backToList">Back to list</Button >
            <br />
            <br />
            <Container style={{ backgroundColor: "#FFFFFF", }}>
                <Grid container >
                    <Grid item xs={3}>
                        <p style={{ float: "left" }}> Job ID:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p style={{ float: "left" }}> {thisJob.id}</p>
                    </Grid>
                    <Grid item xs={3}>
                        <p style={{ float: "left" }}>  Hiring Department:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p style={{ float: "left" }}>  {thisJob.hiringDepartment}</p>
                    </Grid>
                    <Grid item xs={3}>
                        <p style={{ float: "left" }}> Job Title:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p style={{ float: "left" }}>   {thisJob.jobTitle}</p>
                    </Grid>
                    <Grid item xs={3}>
                        <p style={{ float: "left" }}> Job Description:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p style={{ float: "left" }}>  {thisJob.jobDescription}</p>
                    </Grid>
                    <Grid item xs={3}>
                        <p style={{ float: "left" }}> Education Level:</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p style={{ float: "left" }}> {thisJob.educationLevel}</p>
                    </Grid>
                </Grid>
                <br />
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead style={{ backgroundColor: "#F9F6F1" }}>
                            <TableRow >
                                <TableCell width="10%" align="right">Full Name</TableCell>
                                <TableCell width="10%" align="right">Email</TableCell>
                                <TableCell width="10%" align="right">Mobile</TableCell>
                                <TableCell width="20%" align="right">Address</TableCell>
                                <TableCell width="20%" align="right">Education Level</TableCell>
                                <TableCell width="10%" align="right">Pre-screening Score</TableCell>
                                <TableCell width="10%" align="right">Resume Attachment</TableCell>
                                <TableCell width="20%" align="right">Decision</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {thisApplicants.map((applicant) => (
                                <TableRow key={applicant.applicantId}>

                                    <TableCell component="th" scope="row" align="right">{applicant.fullName}</TableCell>
                                    <TableCell align="right">{applicant.email}</TableCell>
                                    <TableCell align="right">{applicant.mobile}</TableCell>
                                    <TableCell align="right">{applicant.address}</TableCell>
                                    <TableCell align="right">{applicant.educationLevel}</TableCell>
                                    <TableCell align="right">{applicant.preScreeningScore}</TableCell>
                                    <TableCell align="right">
                                        <Button onClick={() => {
                                            const doc = new jsPDF();
                                            doc.text(applicant.fullName + "\n" + applicant.email, 10, 10);
                                            doc.save(applicant.fullName);
                                        }} variant="contained"> Download
                                        </Button>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Decision jobEducationLevel={thisJob.educationLevel} applicant={applicant} oldRecord={JSON.parse(allApplicants).find(person => (person.applicantId === applicant.applicantId && person.jobId === applicant.jobId)) ? JSON.parse(allApplicants).find(person => (person.applicantId === applicant.applicantId && person.jobId === applicant.jobId)) : ""} render={function (isCorrect) {
                                            let currentApplicants = JSON.parse(allApplicants);
                                            if (isCorrect === true) {
                                                if (!currentApplicants.some(person => (person.applicantId === applicant.applicantId && person.jobId === applicant.jobId))) {
                                                    currentApplicants.push({
                                                        applicantId: applicant.applicantId,
                                                        jobId: applicant.jobId,
                                                        fullName: applicant.fullName,
                                                        email: applicant.email,
                                                        mobile: applicant.mobile,
                                                        address: applicant.address,
                                                        educationLevel: applicant.educationLevel,
                                                        preScreeningScore: applicant.preScreeningScore,
                                                        resumeAttachment: applicant.resumeAttachment
                                                    });
                                                } else {
                                                    currentApplicants = currentApplicants.filter(person => !(person.applicantId === applicant.applicantId && person.jobId === applicant.jobId));
                                                    currentApplicants.push({
                                                        applicantId: applicant.applicantId,
                                                        jobId: applicant.jobId,
                                                        fullName: applicant.fullName,
                                                        email: applicant.email,
                                                        mobile: applicant.mobile,
                                                        address: applicant.address,
                                                        educationLevel: applicant.educationLevel,
                                                        preScreeningScore: applicant.preScreeningScore,
                                                        resumeAttachment: applicant.resumeAttachment
                                                    });
                                                }
                                            }
                                            else if (isCorrect === false) {
                                                currentApplicants = currentApplicants.filter(person => !(person.applicantId === applicant.applicantId && person.jobId === applicant.jobId));
                                                currentApplicants.push({
                                                    applicantId: applicant.applicantId,
                                                    jobId: applicant.jobId,
                                                    fullName: ""
                                                });
                                            }
                                            else {
                                                currentApplicants = currentApplicants.filter(person => !(person.applicantId === applicant.applicantId && person.jobId === applicant.jobId));
                                            }
                                            currentApplicants.sort(function (a, b) {
                                                return parseFloat(a.applicantId) - parseFloat(b.applicantId);
                                            });
                                            setAllApplicants(JSON.stringify(currentApplicants));
                                        }} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
            </Container>
        </div >
    );
}
export default JobDetails