const finalCodeData = [
    {
        chapterId: 2,
        fileName: "main.tag",
        code: "tagui login.tag",
    },
    {
        chapterId: 2,
        fileName: "login.tag",
        code: "https://rpaexercise.aisingapore.org/login\ntype username as jane007\ntype password as TheBestHR123\nclick login",
    },

    {
        chapterId: 4,
        fileName: "main.tag",
        code: "tagui login.tag\ntagui newjobposting.tag",
    },
    {
        chapterId: 4,
        fileName: "login.tag",
        code: "https://rpaexercise.aisingapore.org/login\ntype username as jane007\ntype password as TheBestHR123\nclick login",
    },
    {
        chapterId: 4,
        fileName: "newjobposting.tag",
        code: "click newJobPosting\ntype jobTitle as Training and Development Specialist\ntype jobDescription as The responsibility of this role is to come up with structured programmes to meet the learning needs of employees.\nselect hiringDepartment as Human Resource\nselect educationLevel as Diploma\ntype postingStartDate as 12/10/2021\ntype postingEndDate as 30/11/2021\nclick //*[@id=\"remote\"]/label[2]/span[1]/span[1]/input\nclick jobTypeFullTime\nclick submit",
    },
    {
        chapterId: 6,
        fileName: "main.tag",
        code: "if iteration equals to 1\n  tagui login.tag\ntagui newjobposting.tag",
    },
    {
        chapterId: 6,
        fileName: "login.tag",
        code: "https://rpaexercise.aisingapore.org/login\ntype username as jane007\ntype password as TheBestHR123\nclick login",
    },
    {
        chapterId: 6,
        fileName: "newjobposting.tag",
        code: "click newJobPosting\ntype jobTitle as `jobTitle`\ntype jobDescription as `jobDescription`\nselect hiringDepartment as `hiringDepartment`\nselect educationLevel as `educationLevel`\ntype postingStartDate as `postingStartDate`\ntype postingEndDate as `postingEndDate`\nif remote equals to \"Yes\"\n  click //*[@id=\"remote\"]/label[1]/span[1]/span[1]/input\nelse  click //*[@id=\"remote\"]/label[2]/span[1]/span[1]/input\nif jobType contains \"Full-time\"\n  click jobTypeFullTime\nif jobType contains \"Part-time\"\n  click jobTypePartTime\nif jobType contains \"Temp\"\n  click jobTypeTemp\nif jobType contains \"Permanent\"\n  click jobTypePermanent\nclick submit",
    },
    {
        chapterId: 7,
        fileName: "main.tag",
        code: "if iteration equals to 1\n  tagui login.tag\ntagui newjobposting.tag\nif iteration equals to 3\n  tagui viewapplicantlist.tag",
    },
    {
        chapterId: 7,
        fileName: "login.tag",
        code: "https://rpaexercise.aisingapore.org/login\ntype username as jane007\ntype password as TheBestHR123\nclick login",
    },
    {
        chapterId: 7,
        fileName: "newjobposting.tag",
        code: "click newJobPosting\ntype jobTitle as `jobTitle`\ntype jobDescription as `jobDescription`\nselect hiringDepartment as `hiringDepartment`\nselect educationLevel as `educationLevel`\ntype postingStartDate as `postingStartDate`\ntype postingEndDate as `postingEndDate`\nif remote equals to \"Yes\"\n  click //*[@id=\"remote\"]/label[1]/span[1]/span[1]/input\nelse\n  click //*[@id=\"remote\"]/label[2]/span[1]/span[1]/input\nif jobType contains \"Full-time\"\n  click jobTypeFullTime\nif jobType contains \"Part-time\"\n  click jobTypePartTime\nif jobType contains \"Temp\"\n  click jobTypeTemp\nif jobType contains \"Permanent\"\n  click jobTypePermanent\nclick submit",
    },
    {
        chapterId: 7,
        fileName: "viewapplicantlist.tag",
        code: "for n from 1 to 3\n  click //*[@id=\"root\"]/div/div/div[2]/div[2]/div[`n`]/div/div[2]/div/a/button\n  click Back to list",
    },
    {
        chapterId: 8,
        fileName: "main.tag",
        code: "if iteration equals to 1\n  tagui login.tag\ntagui newjobposting.tag\nif iteration equals to 3\n  tagui viewapplicantlist.tag",
    },
    {
        chapterId: 8,
        fileName: "login.tag",
        code: "https://rpaexercise.aisingapore.org/login\ntype username as jane007\ntype password as TheBestHR123\nclick login",
    },
    {
        chapterId: 8,
        fileName: "newjobposting.tag",
        code: "click newJobPosting\ntype jobTitle as `jobTitle`\ntype jobDescription as `jobDescription`\nselect hiringDepartment as `hiringDepartment`\nselect educationLevel as `educationLevel`\ntype postingStartDate as `postingStartDate`\ntype postingEndDate as `postingEndDate`\nif remote equals to \"Yes\"\n  click //*[@id=\"remote\"]/label[1]/span[1]/span[1]/input\nelse  click //*[@id=\"remote\"]/label[2]/span[1]/span[1]/input\nif jobType contains \"Full-time\"\n  click jobTypeFullTime\nif jobType contains \"Part-time\"\n  click jobTypePartTime\nif jobType contains \"Temp\"\n  click jobTypeTemp\nif jobType contains \"Permanent\"\n  click jobTypePermanent\nclick submit",
    },
    {
        chapterId: 8,
        fileName: "viewapplicantlist.tag",
        code: "for n from 1 to 3\n  click //*[@id=\"root\"]/div/div/div[2]/div[2]/div[`n`]/div/div[2]/div/a/button\n  tagui processapplicants.tag\n  click Back to list",
    },
    {
        chapterId: 8,
        fileName: "processapplicants.tag",
        code: "read //*[@id=\"root\"]/div/div/div[2]/div[2]/div[1]/div[10]/p to jobEducationLevel\nrows = count('//*[@id=\"root\"]/div/div/div[2]/div[2]/div[2]/table/tbody/tr')\nfor n from 1 to rows\n  read //*[@id=\"root\"]/div/div/div[2]/div[2]/div[2]/table/tbody/tr[`n`]/td[4]  to applicantEducationLevel\n  read //*[@id=\"root\"]/div/div/div[2]/div[2]/div[2]/table/tbody/tr[`n`]/td[5] to preScreeningScore\n  if jobEducationLevel equals to applicantEducationLevel and preScreeningScore greater than 70\n    click //*[@id=\"root\"]/div/div/div[2]/div[2]/div[2]/table/tbody/tr[`n`]/td[7]/div/div/button[1]\n  else\n    click //*[@id=\"root\"]/div/div/div[2]/div[2]/div[2]/table/tbody/tr[`n`]/td[7]/div/div/button[2]",
    },
]
export default finalCodeData
