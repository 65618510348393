import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default function Decision(props) {

    let initialAlignment = "";
    //wrong
    if (typeof props.oldRecord.fullName !== 'undefined') {
        if (props.oldRecord.fullName.toString() === '') {
            if (((props.applicant.preScreeningScore > 70) && (props.applicant.educationLevel === props.jobEducationLevel)) || ((props.applicant.preScreeningScore <= 70) && (props.applicant.educationLevel !== props.jobEducationLevel))) initialAlignment = "right";
            if (((props.applicant.preScreeningScore <= 70) && (props.applicant.educationLevel === props.jobEducationLevel)) || ((props.applicant.preScreeningScore > 70) && (props.applicant.educationLevel !== props.jobEducationLevel))) initialAlignment = "left"
        }
        if (props.oldRecord.fullName.toString() !== '') {
            if (props.applicant.preScreeningScore > 70) initialAlignment = "left";
            if (props.applicant.preScreeningScore <= 70) initialAlignment = "right"
        }
    }

    const [alignment, setAlignment] = React.useState(initialAlignment);

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
        if (newAlignment === null) {
            props.render(null);
        } else if (((props.applicant.preScreeningScore > 70 && newAlignment === "left") && (props.applicant.educationLevel === props.jobEducationLevel)) || ((props.applicant.preScreeningScore <= 70 && newAlignment === "right") && (props.applicant.educationLevel === props.jobEducationLevel)) || (newAlignment === "right" && props.applicant.educationLevel !== props.jobEducationLevel)) {
            props.render(true);
        } else {
            props.render(false);
        }
    };

    return (
        <div>
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                size="small"
            >
                <ToggleButton value="left" aria-label="left aligned" >Approve
                </ToggleButton>
                <ToggleButton value="right" aria-label="right aligned" >Reject
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    )
}