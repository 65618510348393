import React, { useState, useEffect } from 'react';


//import components
import NewJobPosting from './NewJobPosting.js';
import JobSummary from './JobSummary.js';

import jobData from '../models/jobsData.js';

function JobsList(props) {
    //const [jobsData, setJobsData] = useState([]);
    const initialCount = localStorage.getItem("count") === null ? 0 : parseInt(localStorage.getItem("count"));
    const [count, setCount] = useState(initialCount);
    const jobsData = jobData.slice(0, count).map(item => item);
    useEffect(() => {
        localStorage.setItem("count", count);
    }, [count]);
    return (
        <div>
            {/* <NewJobPosting render={function (job) {
                setJobsData([...jobsData, job]);
                console.log(jobsData)
            }} />
            <div>{jobsData.map(item => <JobSummary key={item.jobId} item={item}  />
            )}
            </div> */}
            <NewJobPosting render={function (jobId) {
                    setCount(jobId);
                }} />
            <div>{jobsData.map(item => {
                return <JobSummary key={item.id} item={item} count={count} render={function (x) {
                    setCount(x);
                }} />

            }
            )}
            </div>
        </div>
    );
}

export default JobsList