import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';


import ProcessFlowPNG from '../assets/processflow.png';
import UsernamePNG from '../assets/Username.PNG'
import PasswordPNG from '../assets/Password.PNG'
import LoginPNG from '../assets/Login.PNG'
import JobDescriptionPNG from '../assets/JobDescription.PNG'
import dtPNG from '../assets/dt.PNG'
import Inspect1PNG from '../assets/Inspect1.png';
import Inspect2PNG from '../assets/Inspect2.png';
import OrPNG from '../assets/or.PNG'
import CongratsPNG from '../assets/congrats.png';

import TagUIStep from './TagUIStep';
import FinalCode from './FinalCode';

import TaguiStepData from '../models/taguiStepData';
import FinalCodeData from '../models/finalCodeData'

import { Link } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightBold,
    },
    contentBody: {
        textAlign: 'left',
        flexGrow: 1,
    },
    taguiStep: {
        margin: 'auto',
        width: 900,
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
    },
    imageDiv: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    table: {
        minWidth: 650,
    },
}));

export default function Landing() {


    const classes = useStyles();

    const finalCodeData = FinalCodeData;



    return (
        <div>
            <Container>
                <div className={classes.root}>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Introduction: The use case and persona</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className={classes.contentBody}>
                                <p>
                                    Welcome to RPA Exercise! The aim of this exercise is to simulate a real life problem statement and explore how Robotics Process Automation (RPA) can help to increase efficiency and accuracy.
                                    Let's see how TagUI - a free RPA tool by AI Singapore can achieve this by following this step by step tutorial.
                                </p>
                                <p>
                                    Jane is a 30 year old Human Resource Hiring Specialist at a MNC. Everyday, Jane processes a large volume of jobs to tackle the company's natural attrition.
                                    Her day to day job scope comprises of 2 main tasks:

                                    <ul>
                                        <li>Task 1: Based on a given CSV list, upload the job postings for various department and roles to the company's job portal</li>
                                        <li>Task 2: Processing applicants for all the uploaded jobs.</li>
                                    </ul>
                                </p>
                                <p>
                                    These tasks require her to strictly follow a set of rules to complete which gets mundane and repetitive over time. Her work is also prone to human errors due to the large amount of processing and data involved.
                                    Jane feels burnt out at work and wonders if there is some sort of automation that can help her out.
                                </p>
                                <p>
                                    Do you think RPA is suitable for this use case? Yes, some characteristics of this use case makes it sound very RPA friendly, such as:
                                    <ul>
                                        <li>Large volume</li>
                                        <li>Rule-based</li>
                                        <li>Mundane and repetitive</li>
                                        <li>Prone to human error</li>
                                    </ul>
                                    Having said this, real life use cases should be evaluated based on a more comprehensive approach, which includes some sort of feasibility study on the website or application to be automated.
                                </p>
                                <br />
                                <p>
                                    <strong>The Process Flow</strong>
                                    <ol>
                                        <li>Login to job portal</li>
                                        <li>Based on a list of jobs in CSV file, add the jobs to the portal</li>
                                        <li>For each job added to the portal, view applicant list and process applicants</li>
                                        <li>Process applicants based on 2 criteria:</li>
                                        <ul>
                                            <li>Job posting education level exactly matches applicant education level</li>
                                            <li>Applicant’s pre-screening score &gt; 70</li>
                                        </ul>
                                    </ol>
                                    Let's look at the process flow diagram to understand the scenario better.
                                </p>
                                <div className={classes.imageDiv}>
                                    <img style={{ maxWidth: 900 }} src={ProcessFlowPNG} />
                                </div>
                                <p>
                                    Are you ready to help Jane? Get started by downloading the list of CSV file, then head over to the challenge website by clicking on the Start Challenge button.
                                </p>
                                <div className={classes.imageDiv}>
                                    <a style={{ textDecoration: 'none' }} href=" https://docs.google.com/uc?export=download&id=1tEHImtjYPP2PPeeelD3nIPcaKFmfSJF8">
                                        <Button color="primary" variant="contained">Download Job List</Button>
                                    </a>
                                </div>
                                <br />
                                <div className={classes.imageDiv}>
                                    <Button component={Link} to="/login" color="primary" variant="contained">Start Challenge</Button>

                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Chapter 1: Introduction to TagUI and XPath</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <div>
                                    <a className="tags" href="https://tagui.readthedocs.io/en/latest/setup.html?highlight=installation">installation</a>
                                </div>
                                <p>
                                    TagUI is a free RPA tool by AI Singapore, a government-funded programme to accelerate AI. If you have not installed TagUI, you can head over <a href="https://tagui.readthedocs.io/en/latest/setup.html">here</a> and follow the instructions to setup.
                                </p>
                                <p>
                                    The concept of XPath is relatively important for web automations in TagUI.
                                    The quickest way to get the XPath or CSS selector of an element is by inspecting them using the browser's dev tools.
                                    You can do so by right clicking on the element and checking out the dev tools, as shown in the images.
                                    We will be using this method of retrieving XPaths for this tutorial.
                                </p>
                                <div className={classes.imageDiv}>
                                    <img style={{ maxWidth: 900 }} src={Inspect1PNG} /><br /><br />
                                </div>
                                <div className={classes.imageDiv}>
                                    <img style={{ maxWidth: 900 }} src={Inspect2PNG} /><br /><br />
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Chapter 2: Login to the job portal</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=visit#visit">visit</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=type#type">type</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/main_concepts.html?highlight=click#click">click</a>
                                <h4>Creating a flow</h4>
                                <p>

                                    Let's create our first TagUI flow to login to the portal.
                                    Launch your favourite text editor and open a blank file.
                                    You may use the default text editor provided with your operating system, such as Notepad (Windows) or TextEdit (Mac).
                                    Save the file as "login.tag" (.tag extension).
                                    We will refer to such files with the .tag extension as flow files from here onwards.
                                </p>
                                <h4>Visiting a webpage</h4>
                                <p>
                                    First we have to tell TagUI to visit the login webpage.
                                    By default, TagUI always runs with the Chrome browser launched whenever you run a flow, unless you run the flow with the no browser option.
                                    To visit a webpage, you can use the visit step which simply comprises the url of the webpage.
                                    The syntax for visit step starts with <span className="highlight">https://url</span> or <span className="highlight">http://url</span>.
                                    In this case, we add the login url to the flow file.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="login.tag" step="https://rpaexercise.aisingapore.org/login"></TagUIStep>
                                </div>
                                <h4>Typing into a text field</h4>
                                <p>
                                    Upon visiting the webpage, we need TagUI to key in the username and password credentials into the respective text fields.
                                    This is when the type step comes in handy.
                                    The syntax is <span className="highlight">type [DOM/XPath/Point/Image] as [text to type]</span>.
                                    Upon inspecting the elements with the browser dev tools, we get "username" and "password" as the id/name attribute, which can be used to identify the elements.
                                </p>

                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="login.tag" step={"type username as jane007\ntype password as TheBestHR123"}></TagUIStep>
                                </div>
                                <h4>Clicking a button</h4>
                                <p>
                                    When we are done filling in the credentials, we have to click the login button.
                                    TagUI can click buttons, links and any elements with the click step.
                                    The syntax is <span className="highlight">click [DOM/XPath/Point/Image]</span>.
                                    Inspecting the browser dev tools give us "login" as the button's id attribute.
                                </p>
                                <div className={classes.taguiStep}>  <TagUIStep flowFile="login.tag" step="click login"></TagUIStep></div>
                                <p>
                                    Save and close the tag file.
                                </p>
                                <h4>Chaining the flow as a subflow</h4>
                                <p>
                                    As part of best practise, we will divide the series of events into multiple bitesized subflows.
                                    An example of a bitesized subflow is the one we just created, our login flow.
                                    Thereafter, we will chain and evoke these subflows with a main flow.
                                    To call one TagUI flow from another TagUI flow, we will introduce the concept of subflows with the tagui step.
                                    The syntax is <span className="highlight">tagui subflow.tag</span>.
                                    Do not confuse this with the tagui command, which is used to run flows in the command prompt
                                    The tagui step is used just like any other step like type, click etc, as an instruction within the flow file.
                                </p>
                                <p>
                                    In the same folder as the flow login.tag, create a new tag file and name it main.tag.
                                    Then, call the login flow that we just created with a tagui step.
                                    If you choose to place the 2 flows (login and main) in different folders, you have to call the absolute file path of the subflow in the main flow.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="main.tag" step="tagui login.tag"></TagUIStep>
                                </div>
                                <p>
                                    Save and close the tag file.
                                    To run the flow, run the command tagui main.tag.
                                </p>
                                <h4>Final code</h4>
                                <div className={classes.taguiStep}>
                                    <FinalCode finalCodeArr={finalCodeData.filter(item => item.chapterId == 2)}></FinalCode>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Chapter 3: Using visual automation to login to the portal (optional)</Typography>
                        </AccordionSummary>

                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <p>
                                    Apart from identifying the web elements using XPath, we can also use visual automation.
                                    Visual Automation executes steps by matching the images on the screen to an image you provide.
                                    It is important to take note that for visual automation, TagUI will only identify the element at a single point, which is the mid point of the image provided.
                                    Also, the screenshot provided has to be unambigious so TagUI can identify the exact field you want it to look for.
                                    For example if there are 2 similar fields, take the screenshot of the field including the boundaries beyond the field to capture something else unique around it.
                                </p>
                                <p>
                                    Let's see how we can rewrite the login flow using visual automation.
                                    First, we have to take snapshots of the 3 elements we are dealing with - the username and password text inputs, as well as the login button.
                                    We will name the snapshot image file as follows, and place them in the same folder as the login flow file.
                                </p>

                                <div className={classes.imageDiv}>
                                    img_username.png
                                </div>
                                <div className={classes.imageDiv}>
                                    <img src={UsernamePNG} />
                                </div>

                                <p>
                                    Here's a caveat.
                                    After typing into the username field, the password field is partially blocked by a tooltip.
                                    We have to include the tooltip in the screenshot, otherwise,TagUI will not be able to identify the password field right after the previous step.
                                </p>
                                <div className={classes.imageDiv}>
                                    img_password.png
                                </div>
                                <div className={classes.imageDiv}>
                                    <img src={PasswordPNG} />
                                </div>
                                <div className={classes.imageDiv}>
                                    img_login.png
                                </div>

                                <div className={classes.imageDiv}>
                                    <img src={LoginPNG} />
                                </div>
                                <br />
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="login.tag" step={"https://rpaexercise.aisingapore.org/login\ntype img_username.png as jane007\ntype img_password.png as TheBestHR123\nclick img_login.png"}></TagUIStep>
                                </div>
                                <p>
                                    Try running the login flow to see how TagUI is able to achieve the same results as what we have done previously.
                                    Having said this, we do not recommend using visual automation to identify elements on web pages, as stability is compromised.
                                    Also, resolution differences make it difficult to replicate a visual automation flow on different machines.
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Chapter 4: Add a new job posting</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=visit#visit">visit</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/main_concepts.html?highlight=click#click">click</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=type#type">type</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=select#select">select</a>
                                <p>
                                    In this chapter, we will learn how to automate adding a new job posting.
                                    Let's create a flow to automate adding the first job to the portal.
                                    Create a new flow file and name it "newjobposting.tag".
                                </p>

                                <p>
                                    Once we are logged in, we see a New Job Posting button in the job portal.
                                    To click on it, we can use the click step, which we are already familiar with.
                                    Inspecting this element in the dev tool gives us an id/name attribute of newJobPosting.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step="click newJobPosting"></TagUIStep>
                                </div>
                                <p>
                                    Upon clicking on the button, a modal box form is launched.
                                    This form contains various elements that match the records in the CSV file provided.
                                    For instance, Job ID, Hiring Department, Job Title etc...
                                    This is a very special form as it is validated such that every job added has to exactly match the records in the CSV file provided, in the correct sequence.
                                    You can always reset (delete) the jobs you have added back to zero by clicking on the Reset Progress button in the navbar at the top.
                                </p>

                                <p>
                                    Let's get started on automating the various fields of the form.
                                    The first 2 fields - Job Title and Job Description, are text input fields, which are similar to the username and password fields.
                                    We can easily make use of the type step again.
                                    Inspecting the element in the browser dev tools give us an id/name attribute of jobTitle and jobDescription respectively.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"type jobTitle as Training and Development Specialist\ntype jobDescription as The responsibility of this role is to come up with structured programmes to meet the learning needs of employees."}></TagUIStep>
                                </div>
                                <p>
                                    <strong>Selecting a dropdown list</strong>
                                </p>
                                <p>
                                    The next 2 fields - Hiring Department and Education Level are dropdown lists controls.
                                    We can use the select step to select an option from the list.
                                    The syntax is <span className="highlight">select [DOM/XPath of select input element] as [option value or text]</span>.
                                    Do take note that the select step works well with dropdown lists that are built with the select tag &lt;select&gt;&lt;option /&gt;&lt;/select&gt;.
                                    Inspecting the element in the browser dev tools give us an id/name attribute of hiringDepartment and educationLevel respectively.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"select hiringDepartment as Human Resource\nselect educationLevel as Diploma"}></TagUIStep>
                                </div>

                                <p>
                                    The next 2 fields - Posting Start Date and Posting End Date, are calendar controls.
                                    Clicking on the calendar icon launches a pop up with selectable dates.
                                    However, the calendar control is also made up of a text input on the left of the icon.
                                    This dual selectable characteristic is quite common in calendar controls, hence we will automate it with the latter method.
                                    More often than not, there are multiple ways to automate an an action with RPA, and we should always choose the most straightforward and stable method.
                                    In this case, we can make use of the type step to fill up the date, a step which we are already familiar with.
                                    Inspecting the element in the browser dev tools give us an id/name attribute of postingStartDate and postingEndDate respectively.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"type postingStartDate as 12/10/2021\ntype postingEndDate as 30/11/2021"}></TagUIStep>
                                </div>
                                <p>
                                    <strong>Selecting radio buttons and checkboxes</strong>
                                </p>
                                <p>
                                    The next field, Remote, is a group of two radio buttons, Yes and No.
                                    We can use the click step to select the elements.
                                    Inspecting the radio button Yes in the browser dev tools give us an id attribute of remote.
                                    However, we notice that the radio button No has the same id attribute, and both radio buttons only differ by their value attribute.
                                    This makes the id attribute ambiguous, hence we will use the XPath to identify the radio buttons.
                                    Copying out the XPath from the browser dev tools, and adding a click step, we get the following.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"click //*[@id=\"remote\"]/label[2]/span[1]/span[1]/input"}></TagUIStep>
                                </div>
                                <p>
                                    The last field, Job Type, is a group of checkboxes.
                                    We can usually treat checkboxes just like buttons use the click step to select them.
                                    Don't forget to submit the new job posting with a click step too.
                                    Inspecting the element in the browser dev tools, we get an id/name attribute of jobTypeFullTime and submit respectively.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"click jobTypeFullTime\nclick submit"}></TagUIStep>
                                </div>
                                <p>
                                    Save and close the tag file.  To run the flow, run the command tagui main.tag again.
                                    Hooray! We have just automated adding one new job posting.
                                </p>
                                <p>
                                    <strong>Final code</strong>
                                </p>
                                <div className={classes.taguiStep}>
                                    <FinalCode finalCodeArr={finalCodeData.filter(item => item.chapterId == 4)}></FinalCode>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Chapter 5: Optimising the automation (optional)</Typography>

                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=clipboard#clipboard">clipboard</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=keyboard#keyboard">keyboard</a>
                                <p>
                                    <strong>Simulating a copy/paste into a text field</strong>
                                </p>
                                <p>
                                    This chapter is optional, as it involves visual automation which we do not recommend for web automations.
                                    You might have noticed, the job descriptions are rather lengthy, and typing it into the text field can take a while.
                                    Let's try to optimise it. We will replace the type step we previously.
                                    Go to newjobposting.tag, and comment out the type step by adding two forward slashes.
                                    Next, we will simulate a copy and paste of the text using the helper function clipboard() and keyboard.
                                    The syntax are  <span className="highlight">clipboard('some text')</span> and <span className="highlight">keyboard [keys]</span>.
                                    Note that the syntax differs slightly for Windows and Mac/Linux.
                                    Comment/uncomment the appropriate step based on your operating system.

                                    We also have to include a visual automation step before doing the copy/paste, to bring the browser into the foreground focus.
                                    We can use a click step on the Job Description input field.
                                    Save a screenshot of the field as img_jobdescription.png and place it in the same folder as your flow file.
                                </p>
                                <div className={classes.imageDiv}>
                                    img_jobdescription.png
                                </div>
                                <div className={classes.imageDiv}>
                                    <img src={JobDescriptionPNG} />
                                </div>
                                <br />
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"// type Job Description as The responsibility of this role is to come up with structured programmes to meet the learning needs of employees.\nclick img_jobdescription.png\nclipboard('The responsibility of this role is to come up with structured programmes to meet the learning needs of employees.')\nkeyboard [ctrl]v\n// for Mac/Linux users, uncomment the line below:\n// keyboard [cmd]v"}></TagUIStep>
                                </div>
                                <p>
                                    Save and close the tag file.
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Chapter 6: Add multiple job postings</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/main_concepts.html?highlight=assign#assign">assign</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/advanced.html#datatables">datatables</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/main_concepts.html?highlight=if#if-conditions">if conditions</a>
                                <p>
                                    This chapter will cover a handful of big concepts, so buckle up for the ride!
                                    Let's revisit our previous flow, newjobposting.tag.
                                    In our flow, notice that all the input values - "Training and Development Specialist", "HR", "Diploma" etc are hardcoded.
                                    This means that the flow will only work for adding the first job posting, and not subsequent job postings.
                                    <br /><br />
                                    Does this mean that we have to write 3 different sets of instruction to automate adding 3 new jobs?

                                    Thankfully we do not have to, as TagUI is able to iterate through a set of data and apply the same set of instructions to each record, through the concept of datatables.

                                    But before we jump into datatables, we have to understand dealing with variables in TagUI.
                                    <br />  <br />
                                    Variables are names used to hold one or more values.
                                    You can assign values into variables to reference and work with.
                                    Once a variable is assigned, it can be accessed later on in the flow by calling it wrapped between backticks. However, note that accessing variables in if conditions and for loops do not require backticks.
                                    For example:<br />
                                </p>
                                <div className={classes.imageDiv}>
                                    <p>
                                        <span className="highlight">
                                            a = hello world </span> <br />
                                        <span className="highlight">echo `a`</span>  <br />
                                        Expected output: hello world
                                    </p>
                                </div>

                                <p>
                                    With that in mind, let's learn more about datatables.
                                    Datatables are csv files which can be used to run your flows multiple times with different inputs.

                                </p>
                                <div className={classes.imageDiv}>
                                    datatable.csv
                                </div>
                                <div className={classes.imageDiv}>
                                    <img src={dtPNG} />
                                </div>
                                <br />
                                <p>
                                    The syntax to run a flow with datatable, run the following command in the command prompt: <span className="highlight">tagui flow.tag datatable.csv</span>.
                                    Within the flow, we can access the current iteration's data through the column header wrapped in between backticks, for example: <span className="highlight">`columnA`</span> , <span className="highlight">`columnB`</span> , <span className="highlight">`columnC`</span><br /><br />
                                    Let's go back to our flow newjobposting.tag, and replace some of the hardcoded input values with variables from the datatable.
                                </p>

                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"click newJobPosting\ntype jobTitle as `jobTitle`\ntype jobDescription as `jobDescription`\nselect hiringDepartment as `hiringDepartment`\nselect educationLevel as `educationLevel`\ntype postingStartDate as `postingStartDate`\ntype postingEndDate as `postingEndDate`"}></TagUIStep>
                                </div>
                                <p>
                                    For the subsequent fields we cannot use the variables directly.
                                    Instead, we will create an if condition based on the variable. The syntax for if condition is:
                                </p>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p>
                                        <span className="highlight">
                                            if some condition </span> <br />
                                        <span className="highlight"> &nbsp;&nbsp;// some steps</span>  <br />
                                    </p>
                                </div>
                                <p>
                                    For the radio buttons, we will use XPath identify the elements.</p>

                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"if remote equals to \"Yes\"\n  click //*[@id=\"remote\"]/label[1]/span[1]/span[1]/input\nelse\n  click //*[@id=\"remote\"]/label[2]/span[1]/span[1]/input"}></TagUIStep>
                                </div>
                                <p>
                                    Do likewise for the checkbox fields, this time using css selectors.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"if jobType contains \"Full-time\"\n  click Full-time\nif jobType contains \"Permanent\"\n  click Permanent\nif jobType contains \"Part-time\"\n  click Part-time\nif jobType contains \"Temp\"\n  click Temp"}></TagUIStep>
                                </div>
                                <p>
                                    Lastly, don't forget to do a click step on the submit button to successfully add the job posting.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"click submit"}></TagUIStep>
                                </div>
                                <p>
                                    Save and close the tag file.
                                </p>
                                <p>
                                    As datatables run the entire flow for each record in the dataset, we have to make some changes to our main.tag file such that it does not run the login subflow multiple times on every record.
                                    In datatables, we can access the current iteration with the iteration variable.
                                    In this case, we only want to run the login flow on the first iteration.
                                    We can do this by adding an if condition to only run the login flow if the iteration is equals to 1.
                                </p>
                                <p>
                                    Go to main.tag and add the following line:
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="main.tag" step={"if iteration equals to 1\n  tagui login.tag"}></TagUIStep>
                                </div>
                                <p>
                                    Save and close the tag file.
                                    Run the flow with the following command: tagui main.tag datatable.csv.
                                </p>
                                <p>
                                    <strong>Final code</strong>
                                </p>
                                <div className={classes.taguiStep}>
                                    <FinalCode finalCodeArr={finalCodeData.filter(item => item.chapterId == 6)}></FinalCode>
                                </div>
                            </Typography>
                        </AccordionDetails>

                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Chapter 7: View applicant list for each job posting</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=visit#visit">visit</a>

                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/main_concepts.html?highlight=if#for-loops">for loops</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/main_concepts.html?highlight=click#click">click</a>
                                <h4>Identifying similar elements on a webpage and looping through them</h4>
                                <p>
                                    In this chapter, we will create a flow to loop through all the posted jobs, view the applicant list for each job, then return back to the job list page .
                                    Let's start by creating a new tag file and name it "viewapplicantlist.tag".
                                </p>
                                <p>
                                    In the previous chapters, we only tackled one of a kind elements on the webpage.
                                    Hence, we could easily identify the xpath of the element as they had a unique id or name attribute,
                                    However, at a glance, there are 3 similar "View Applicant List" buttons on the page.
                                    There are 2 questions that we will answer in this chapter:
                                    How do we identify each of the 3 buttons?
                                    How do we loop through all 3 buttons and click on them one by one?
                                </p>
                                <p>
                                    Let's gather the XPath of first two "View Applicant List" buttons and compare them side by side.
                                </p>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p > //*[@id="root"]/div/div/div[2]/div[2]/div[<span style={{ backgroundColor: "#ffff00" }}>1</span>]/div/div[2]/div/a/button<br />
                                    //*[@id="root"]/div/div/div[2]/div[2]/div[<span style={{ backgroundColor: "#ffff00" }}>2</span>]/div/div[2]/div/a/button<br /> </p>
                                    <br />
                                </div>
                                <p>
                                    We can see that they only differ by a number as highlighted.

                                    At this juncture we will introduce the concept of loops. The syntax is:
                                </p>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p ><span className="highlight"> for n from 1 to 20   </span><br />
                                        <span className="highlight">&nbsp;&nbsp;// some steps<br /></span></p>
                                </div>
                                <p>
                                    Do note the indentation required for steps within a for loop. Within the loop, the current loop iteration can be accessed just like any other variable, by calling it in between backticks `n`. <br /><br />
                                    Applying this to our use case, we will have:
                                </p>

                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="viewapplicantlist.tag" step={"for n from 1 to 3\n  click //*[@id=\"root\"]/div/div/div[2]/div[2]/div[`n`]/div/div[2]/div/a/button/span[1]\n  click Back to list"}></TagUIStep>
                                </div>
                                <p>
                                    Save and close the tag file.
                                    <br /><br />
                                    Putting this together in the main flow, we have to ensure that this flow only runs after all the job postings are added.
                                    We can do so by adding an if condition to check that it is only called at the last iteration.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="main.tag" step={"if iteration equals to 3\n  tagui viewapplicantlist.tag"}></TagUIStep>
                                </div>
                                <p>
                                    Save and close the tag file.
                                </p>
                                <p>
                                    <strong>Final code</strong>
                                </p>
                                <div className={classes.taguiStep}>
                                    <FinalCode finalCodeArr={finalCodeData.filter(item => item.chapterId == 7)}></FinalCode>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Chapter 8: Process applicants with rule-based decisions for each job posting</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=count#count">count()</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/main_concepts.html?highlight=if#for-loops">for loops</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=read#read">read</a>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/main_concepts.html?highlight=if#if-conditions">if conditions</a>
                                <h4>Conditions for processing applicants</h4>
                                <p>
                                    In this chapter, we will be processing the applicants for each job posting, based on the following 2 criteria:<br />
                                    Approve the applicant if:
                                </p>
                                <ul>
                                    <li>The applicant's education level exactly matches the education level requirement of the job</li>
                                    <li>The applicant's pre-screening score is &gt; 70</li>
                                </ul>
                                <p>
                                    Otherwise, reject the applicant.<br />
                                </p>
                                <h4>Reading the current job posting's education level requirement</h4>
                                <p>
                                    Let's take a look at how we can apply these condition to the first job.
                                    First, create a new tag file and name it "processapplicants.tag".
                                    Next, we will use the read step to get the value of the job posting's education level.
                                    The syntax for read step is <span className="highlight">read [DOM/XPath/Region/Image] to [variable]</span>.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="processapplicants.tag" step={"read //*[@id=\"root\"]/div/div/div[2]/div[2]/div[1]/div[10]/p to jobEducationLevel"}></TagUIStep>
                                </div>
                                <h4>Finding the total number of applicants for the current job posting</h4>
                                <p>
                                    In the previous chapter, we learnt how to loop through a fix number of elements (in that case, 5 jobs).

                                    However, in this scenario, the number of applicants for each job is not known beforehand and varies from job to job.
                                    Is there a way to get the total number of applicants dynamically for each job?
                                    At this juncture, we will introduce the helper function count().
                                    The syntax is <span className="highlight">count('table-rows')</span>.
                                </p>

                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="processapplicants.tag" step={"rows = count('//*[@id=\"root\"]/div/div/div[2]/div[2]/div[2]/table/tbody/tr'\)"}></TagUIStep>
                                </div>
                                <p>
                                    We will then loop through all the rows and read the education level and pre-screening score values for each applicant into variables called jobEducationLevel and applicantEducationLevel respectively.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="processapplicants.tag" step={"for n from 1 to rows\n  read //*[@id=\"root\"]/div/div/div[2]/div[2]/div[2]/table/tbody/tr[`n`]/td[4] to applicantEducationLevel\n  read //*[@id=\"root\"]/div/div/div[2]/div[2]/div[2]/table/tbody/tr[`n`]/td[5] to preScreeningScore\n  // process applicant"}></TagUIStep>
                                </div>
                                <h4>Making text and numeric comparisons using conditions</h4>
                                <p>
                                    Now let's write the logic to process (approve/reject) the applicants using if conditions. The list of conditions available in TagUI are:
                                    <span className="highlight">
                                        contain
                                        not contain
                                        equal to
                                        not equal to
                                        more than / greater than / higher than
                                        more than or equal to / greater than or equal to / higher than or equal to
                                        less than / lesser than / lower than
                                        less than or equal to / lesser than or equal to / lower than or equal to
                                        and
                                        or</span>.
                                    We will use the "equals to" and "greater than" conditions for our comparison.
                                    If the conditions are matched we will click on the Approve button, else, we will click on the Reject button.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="processapplicants.tag" step={"  if jobEducationLevel equals to applicantEducationLevel and preScreeningScore greater than 70\n    click //*[@id=\"root\"]/div/div/div[2]/div[2]/div[2]/table/tbody/tr[`n`]/td[7]/div/div/button[1]\n  else\n    click //*[@id=\"root\"]/div/div/div[2]/div[2]/div[2]/table/tbody/tr[`n`]/td[7]/div/div/button[2]"}></TagUIStep>
                                </div>
                                <h4>Integrating this flow with the other flows</h4>
                                <p>
                                    Let us revisit the flow "viewapplicantlist.tag".
                                    To recap what the flow does - it loops through the list of jobs and views the applicant list for each job.
                                    Instead of just viewing the applicant list, we want to process the applicants as well.
                                    To do that, we can call "processapplicants.tag" as a subflow by adding the one liner - tagui processapplicants.tag.
                                    After adding, the flow should look like this.
                                </p>
                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="viewapplicantlist.tag" step={"for n from 1 to 3\n  click //*[@id=\"root\"]/div/div/div[2]/div[2]/div[`n`]/div/div[2]/div/a/button/span[1]\n  tagui processapplicants.tag\n  click Back to list"}></TagUIStep>
                                </div>
                                <p>
                                    Save and close the tag file. To run the automation from end to end, run tagui main.tag in the command prompt.
                                </p>
                                <p>
                                    <strong>Final code</strong>
                                </p>
                                <div className={classes.taguiStep}>
                                    <FinalCode finalCodeArr={finalCodeData.filter(item => item.chapterId == 8)}></FinalCode>

                                </div>
                                <br />
                                <div className={classes.imageDiv}>
                                    <a style={{ textDecoration: 'none', textAlign: 'center' }} href=" https://docs.google.com/uc?export=download&id=15saIUC6FTeYEE3yblVEQ7kj_0o1RTeMg">
                                        <Button color="primary" variant="contained">Download full solution</Button>
                                    </a>
                                </div>

                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Chapter 9: Hiding XPath in flows with object repository (optional)</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/advanced.html?highlight=object%20repository#object-repositories">object repositories</a>
                                <p>
                                    Object repositories are optional csv files which can store variables for use in flows.
                                    Each flow has a local object repository and all flows share the global object repository. The local object repository is the tagui_local.csv in the same folder as the flow. The global object repository is the tagui_global.csv in the tagui/src folder.
                                </p>
                                <p>
                                    In this chapter, we will learn how to hide XPaths in our flows with a local object repository.
                                    We will be hiding the XPaths for the radio buttons in newjobposting.tag.
                                    Let's start by creating a new CSV file in the same folder as our flow files, and name it "tagui_local.csv". Define 2 columns, object - a variable name we define, and identifier - the XPath of the element.

                                </p>
                                <p>
                                    Revisit newjobposting.tag and replace the XPath for the remote radio button with our newly defined variable in the object repository, radioButtonYes and radioButtonNo.
                                </p>
                                <div className={classes.imageDiv}>
                                    tagui_local.csv
                                </div>
                                <div className={classes.imageDiv}>

                                    <img style={{ maxWidth: 900 }} src={OrPNG} />
                                </div>

                                <p>
                                    Revisit newjobposting.tag and replace the XPath for the remote radio button with our newly defined variable in the object repository, radioButtonYes and radioButtonNo.
                                </p>


                                <div className={classes.taguiStep}>
                                    <TagUIStep flowFile="newjobposting.tag" step={"if remote equals to \"Yes\"\n  click `radioButtonYes`\nelse\n  click `radioButtonNo`"}></TagUIStep>
                                </div>
                                <p>
                                    Save and close the tag file.
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Chapter 10: Error handling in TagUI</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/advanced.html?highlight=error#handling-exceptions-and-errors">error and exception handling </a>
                                <p>
                                    Let's open the folder that contains all our flow files.
                                    Did you notice a new image file called main_error.png which looks like this?
                                </p>
                                <div className={classes.imageDiv}>
                                    main_error.png
                                </div>
                                <div className={classes.imageDiv}>

                                    <img style={{ maxWidth: 900 }} src={CongratsPNG} /><br /><br />
                                </div>
                                <p>
                                    This image was automatically created by TagUI when it detected that an expected user interface element was missing.
                                    This happened because after approving all the applicants, the last step in viewapplicantlist.tag is supposed to click Back to list.
                                    However, the webpage renders an easter egg surprise when all the applicants are processed correctly.
                                    Hence, the Back to list button is no where to be found on the user interface.
                                    <br /><br />
                                    TagUI handles errors or exceptions in various ways. You can find out more on this topic <a href="https://tagui.readthedocs.io/en/latest/advanced.html?highlight=error#handling-exceptions-and-errors">here</a>.
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Chapter 11: Deploying and scheduling the flow</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <a className="tags" href="https://tagui.readthedocs.io/en/latest/reference.html?highlight=deploy#deploy-or-d">deploy</a>

                                <p>
                                    So far, we have only been running our flows, which starts the automation almost immediately.
                                    With TagUI, we can also deploy our flows, which packages our flow into an executable file.
                                    To do so, we can append the deploy option -deploy or -d when running our command in the command prompt. For example: tagui main.tag -deploy
                                </p>
                                <p>
                                    You can run the generated executable file by double clicking on it.
                                    With this file, you can also schedule your flows with in-built scheduler application on your machine.
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Epilogue: Congratulations you're a TagUI RPA Champion!</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.contentBody}>
                            <Typography>
                                <p>
                                    Congratulations! You have successfully automated this use case from end to end.
                                    We hope you enjoyed learning TagUI, and gained some insights to how you may use TagUI to supplement your own daily work.
                                    Remember to join the TagUI community on  <a href="https://t.me/rpa_chat">Telegram</a> as well as <a href="https://community.aisingapore.org/groups/tagui-rpa/">AI Singapore's TagUI Community</a> to learn from other TagUI experts.
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <br />

            </Container>
            <br /><br />
            <br /><br />
        </div >

    )
}