import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container'
import { Alert, AlertTitle } from '@material-ui/lab';


import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import Input from '@material-ui/core/Input';

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import jobsData from '../models/jobsData';
export default function FormDialog(props) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // select style
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
            ...theme.typography.button,
            backgroundColor: theme.palette.background.paper,
        },
    }));
    const classes = useStyles();
    const initialCount = localStorage.getItem("count") === null ? 0 : parseInt(localStorage.getItem("count"));
    const [inputData, setInputData] = useState({
        jobId: initialCount + 1,
        hiringDepartment: "",
        jobTitle: "",
        jobDescription: "",
        educationLevel: "",
        postingStartDate: null,
        postingEndDate: null,
        jobTypeFullTime: false,
        jobTypePartTime: false,
        jobTypeTemp: false,
        jobTypePermanent: false,
        remote: "Yes"
    });

    const [jobs, setJobs] = useState(jobsData);

    const [error, setError] = useState();
    useEffect(() => {
    }, [error]);
    function handlePostingStartDateChange(date) {
        setInputData(prevInputData => ({ ...prevInputData, postingStartDate: date }));
    };

    function handlePostingEndDateChange(date) {
        setInputData(prevInputData => ({ ...prevInputData, postingEndDate: date }));
    };

    function handleChange(event) {
        const { name, value, type, checked } = event.target;
        if (type === "checkbox") setInputData(prevInputData => ({ ...prevInputData, [name]: checked }));
        else setInputData(prevInputData => ({ ...prevInputData, [name]: value }));
        setError(undefined);
    }
    function handleSubmit(event) {
        event.preventDefault();
        const matchedJob = jobs.find(x => x.id === inputData.jobId)
        if (
            inputData.hiringDepartment === matchedJob.hiringDepartment &&
            inputData.jobTitle === matchedJob.jobTitle &&
            // remove validation for job description
            // inputData.jobDescription === matchedJob.jobDescription &&
            inputData.educationLevel === matchedJob.educationLevel &&
            inputData.postingStartDate.toLocaleDateString('en-GB') === matchedJob.postingStartDate &&
            inputData.postingEndDate.toLocaleDateString('en-GB') === matchedJob.postingEndDate &&
            inputData.jobTypeFullTime === matchedJob.jobTypeFullTime &&
            inputData.jobTypePartTime === matchedJob.jobTypePartTime &&
            inputData.jobTypeTemp === matchedJob.jobTypeTemp &&
            inputData.jobTypePermanent === matchedJob.jobTypePermanent &&
            inputData.remote === matchedJob.remote
        ) {
            setInputData(prevInputData => ({
                ...prevInputData,
                jobId: prevInputData.jobId + 1,
                hiringDepartment: "",
                jobTitle: "",
                jobDescription: "",
                educationLevel: "",
                postingStartDate: null,
                postingEndDate: null,
                jobTypeFullTime: false,
                jobTypePartTime: false,
                jobTypeTemp: false,
                jobTypePermanent: false,
                remote: "Yes"
            }));
            matchedJob.added = true;
            props.render(inputData.jobId);
            localStorage.setItem("count", inputData.jobId.toString())
            handleClose();
        }
        // set error message if not exact match
        else {
            let errorArr = [];
            if (inputData.jobTitle !== matchedJob.jobTitle) errorArr.push("Job Title");
            if (inputData.hiringDepartment !== matchedJob.hiringDepartment) errorArr.push("Hiring Department");
            if (inputData.educationLevel !== matchedJob.educationLevel) errorArr.push("Education Level");
            if (inputData.postingStartDate !== null) {
                if (inputData.postingStartDate.toLocaleDateString('en-GB') !== matchedJob.postingStartDate) errorArr.push("Posting Start Date");
            }
            else {
                errorArr.push("Posting Start Date");
            }
            if (inputData.postingEndDate !== null) {
                if (inputData.postingEndDate.toLocaleDateString('en-GB') !== matchedJob.postingEndDate) errorArr.push("Posting End Date");
            }
            else {
                errorArr.push("Posting End Date");
            }
            if (inputData.remote !== matchedJob.remote) errorArr.push("Remote");
            if ((inputData.jobTypeFullTime !== matchedJob.jobTypeFullTime) || (inputData.jobTypePartTime !== matchedJob.jobTypePartTime) || (inputData.jobTypeTemp !== matchedJob.jobTypeTemp) || (inputData.jobTypePermanent !== matchedJob.jobTypePermanent)) errorArr.push("Job Type");
            setError(errorArr);
        }
    }
    return (
        <div>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12}>

                        <Button variant="contained" onClick={handleClickOpen} id="newJobPosting" name="newJobPosting">
                            New Job Posting
                        </Button>
                        <Dialog open={open} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">New Job Posting</DialogTitle>
                            <DialogContent>
                                <FormControl className={classes.formControl}>
                                    <label>Job ID: </label>
                                    <Input defaultValue={inputData.jobId} disabled inputProps={{ 'aria-label': 'jobId' }} />

                                </FormControl>
                                <br />
                                <FormControl style={{ minWidth: 200 }} className={classes.formControl}>

                                    <TextField id="jobTitle" label="Job Title" type="text"
                                        name="jobTitle"
                                        value={inputData.jobTitle}
                                        onChange={handleChange} />
                                </FormControl>

                                <FormControl style={{ minWidth: 500 }} className={classes.formControl}>
                                    <TextField
                                        margin="dense"
                                        fullWidth
                                        multiline
                                        id="jobDescription"
                                        name="jobDescription"
                                        value={inputData.jobDescription}
                                        label="Job Description"
                                        onChange={handleChange} />
                                </FormControl>
                                <FormControl style={{ minWidth: 200 }} className={classes.formControl}>
                                    <div className="select">
                                        <select name="hiringDepartment" id="hiringDepartment" value={inputData.hiringDepartment} onChange={handleChange} >
                                            <option value="" disabled selected>Hiring Department</option>
                                            <option value="Business Development">Business Development</option>
                                            <option value="Engineering">Engineering</option>
                                            <option value="Finance">Finance</option>
                                            <option value="Human Resource">Human Resource</option>
                                            <option value="Legal">Legal</option>
                                        </select>
                                    </div>
                                </FormControl>
                                <br />
                                <FormControl style={{ minWidth: 200 }} className={classes.formControl}>
                                    <div className="select">
                                        <select name="educationLevel" id="educationLevel" value={inputData.educationLevel} onChange={handleChange}>
                                            <option value="" disabled selected>Education Level</option>
                                            <option value="Diploma">Diploma</option>
                                            <option value="Degree">Degree</option>
                                        </select>
                                    </div>
                                </FormControl>
                                <br />
                                {/* <FormControl style={{ minWidth: 200 }} className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Hiring Department</InputLabel>
                                    <Select
                                        labelId="hiringDepartment"
                                        name="hiringDepartment"
                                        id="hiringDepartment"
                                        value={inputData.hiringDepartment}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Admin">Admin</MenuItem>
                                        <MenuItem value="Business Development">Business Development</MenuItem>
                                        <MenuItem value="Engineering">Engineering</MenuItem>
                                        <MenuItem value="Finance">Finance</MenuItem>
                                        <MenuItem value="HR">HR</MenuItem>
                                        <MenuItem value="Legal">Legal</MenuItem>
                                    </Select>
                                </FormControl> */}
                                {/* <FormControl style={{ minWidth: 200 }} className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Education Level</InputLabel>
                                    <Select
                                        labelId="educationLevel"
                                        name="educationLevel"
                                        id="educationLevel"
                                        value={inputData.educationLevel}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Diploma">Diploma</MenuItem>
                                        <MenuItem value="Degree">Degree</MenuItem>
                                    </Select>
                                </FormControl> */}
                                <FormControl className={classes.formControl}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justifyContent="space-around">
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="postingStartDate"
                                                label="Posting Start Date"
                                                value={inputData.postingStartDate}
                                                onChange={handlePostingStartDateChange}
                                                name="postingStartDate"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                autoOk={true}
                                            />

                                        </Grid>
                                        <Grid container justifyContent="space-around" >
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="postingEndDate"
                                                label="Posting End Date"
                                                value={inputData.postingEndDate}
                                                onChange={handlePostingEndDateChange}
                                                name="postingEndDate"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                autoOk={true}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </FormControl><br />
                                <FormControl className={classes.formControl}>
                                    <FormLabel component="legend">Remote</FormLabel>
                                    <RadioGroup aria-label="remote" name="remote" id="remote">
                                        <FormControlLabel type="radio" checked={inputData.remote === "Yes"} onChange={handleChange} value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel type="radio" checked={inputData.remote === "No"} onChange={handleChange} value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                                <br />
                                <FormControl className={classes.formControl}>
                                    <FormLabel component="legend">Job Type</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox type="checkbox"
                                                id="jobTypeFullTime"
                                                name="jobTypeFullTime"
                                                checked={inputData.jobTypeFullTime}
                                                onChange={handleChange} />}
                                            label="Full-time"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                type="checkbox"
                                                id="jobTypePartTime"
                                                name="jobTypePartTime"
                                                checked={inputData.jobTypePartTime}
                                                onChange={handleChange} />}
                                            label="Part-time"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                type="checkbox"
                                                id="jobTypeTemp"
                                                name="jobTypeTemp"
                                                checked={inputData.jobTypeTemp}
                                                onChange={handleChange} />}
                                            label="Temp"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                type="checkbox"
                                                id="jobTypePermanent"
                                                name="jobTypePermanent"
                                                checked={inputData.jobTypePermanent}
                                                onChange={handleChange} />}
                                            label="Permanent"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <br />

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary" name="cancel" id="cancel">
                                    Cancel
                                </Button>
                                <Button onClick={handleSubmit} color="primary" name="submit" id="submit">
                                    Submit
                                </Button>
                            </DialogActions>

                            {error !== undefined ? <Alert severity="error"><AlertTitle>Error</AlertTitle>These fields do not match the records in the datatable provided: <br />{error.map((item, i) => (i !== error.length - 1) ? item + ", " : item)}<br /><strong>Please check and resubmit!</strong></Alert> : null}
                        </Dialog>
                    </Grid>
                </Grid>
            </Container>
        </div >
    );
}
