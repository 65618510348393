import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});
const d = new Date();
export default function Congratulations() {
    const classes = useStyles();
    return (
        <div>
            <br />
            <Card style={{ backgroundColor: "#ffffff", width: 600, marginLeft: "auto", marginRight: "auto" }} className={classes.root}>
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Congratulations!
                    </Typography>
                    <Typography variant="body2" component="p">
                        You're a TagUI RPA Champ! <br /><br />We hope you enjoyed learning RPA through this RPA Exercise.
                        Continue your automation journey by learning from experienced RPA folks like you all by joining the TagUI community on telegram.
                        <br /><br />
                        {d.toLocaleString('en-SG')}
                    </Typography>
                </CardContent></Card>
        </div>
    )
}