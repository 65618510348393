import React from 'react';

//import react-router
import { Link, withRouter } from 'react-router-dom';

//import material-ui
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});
function JobSummary(props) {

    const classes = useStyles();

    // const jobs = jobsData.map(job =>
    //     <div key={job.id}>
    //         <Link to={`/jobs/${job.id}`}>
    //             <h3>{job.added.toString()}</h3>
    //             <h3>{job.jobTitle}</h3>
    //             <h3>{job.hiringDepartment}</h3>
    //             <hr></hr>
    //         </Link>
    //     </div>
    //)
    function handleClick(event) {
        props.history.push("/jobs/" + props.item.jobId);
        console.log(props.jobs[0])
    }
    return (
        <div>
            {/* <button onClick={handleClick}></button> */}


            <br />

            <Card style={{ backgroundColor: "#ffffff", width: 600, marginLeft: "auto", marginRight: "auto" }} className={classes.root}>
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {props.item.jobId}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {props.item.jobTitle}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {props.item.hiringDepartment}
                    </Typography>
                    <Typography variant="body2" component="p">
                        {props.item.jobDescription}
                    </Typography>
                </CardContent>
                <Grid container style={{ display: "flex", justifyContent: "flex-start"}}>
                    <CardActions>
                        <Link style={{ textDecoration: 'none' }} to={`/jobs/${props.item.id}`} >
                            <Button size="small">View Applicant List</Button>
                        </Link>
                    </CardActions>
                </Grid>
            </Card>

        </div >

    )

}
export default withRouter(JobSummary)