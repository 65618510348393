const jobData = [
    {
        id: 1,
        hiringDepartment: "Human Resource",
        jobTitle: "Training and Development Specialist",
        jobDescription: "The responsibility of this role is to come up with structured programmes to meet the learning needs of employees.",
        educationLevel: "Diploma",
        postingStartDate: "12/10/2021",
        postingEndDate: "30/11/2021",
        jobTypeFullTime: true,
        jobTypePartTime: false,
        jobTypeTemp: false,
        jobTypePermanent: false,
        remote: "No",
        added: false
    },
    {
        id: 2,
        hiringDepartment: "Engineering",
        jobTitle: "Senior Software Engineer",
        jobDescription: "This role participates in the full software development life cycle of internal enterprise applications.",
        educationLevel: "Degree",
        postingStartDate: "15/10/2021",
        postingEndDate: "14/11/2021",
        jobTypeFullTime: true,
        jobTypePartTime: false,
        jobTypeTemp: false,
        jobTypePermanent: true,
        remote: "Yes",
        added: false
    },
    {
        id: 3,
        hiringDepartment: "Finance",
        jobTitle: "Accountant",
        jobDescription: "The responsibility of this role is to provide the full spectrum of accounting support to the Head of Finance.",
        educationLevel: "Degree",
        postingStartDate: "24/11/2021",
        postingEndDate: "23/12/2021",
        jobTypeFullTime: false,
        jobTypePartTime: true,
        jobTypeTemp: true,
        jobTypePermanent: false,
        remote: "No",
        added: false
    }
]

export default jobData