import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

//import contextconsumer
import { LoginContextConsumer } from '../context/LoginContext.js';

//import material-ui
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    }, title: {
        fontSize: 20,
        color: "#000"
    }
}));

function Login(props) {
    const classes = useStyles();
    const [loginData, setLoginData] = useState({ username: "", password: "" });
    const [isLoggedIn, setLoginStatus] = useState(false);
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    useEffect(() => {
        localStorage.setItem("isLoggedIn", isLoggedIn);
    }, [isLoggedIn]);
    useEffect(() => {
    }, [usernameError]);
    useEffect(() => {
    }, [passwordError]);
    function handleSubmit(context, event) {
        event.preventDefault();

        if (loginData.username !== "jane007") {
            setUsernameError("Incorrect username");
        }
        if (loginData.password !== "TheBestHR123") {
            setPasswordError("Incorrect password");
        }
        if (loginData.username === "jane007" && loginData.password === "TheBestHR123") {
            context.changeLoginStatus();
            props.history.push("/jobs");
        }
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setLoginData(prevLoginData => {
            return {
                ...prevLoginData,
                [name]: value
            }
        });
        if (name === "username") setUsernameError("");
        if (name === "password") setPasswordError("");
    }
    return (
        <div>

            <Card alignItems="center" style={{ width: 600, marginLeft: "auto", marginRight: "auto" }} className={classes.root}>
                <br />
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Login
                </Typography>
                <LoginContextConsumer>
                    {context => (
                        <form className={classes.root} noValidate autoComplete="off" onSubmit={(event) => handleSubmit(context, event)}>
                            <Tooltip title="jane007">
                                <TextField
                                    id="outlined-search"
                                    label="Username"
                                    variant="outlined"
                                    type="text"
                                    name="username"
                                    placeholder="Username"
                                    onChange={handleChange}
                                    helperText={usernameError}
                                    error={usernameError} />
                            </Tooltip>
                            <br />
                            <Tooltip title="TheBestHR123">
                                <TextField
                                    id="password"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    name="password"
                                    placeholder="Password"
                                    onChange={handleChange}
                                    helperText={passwordError}
                                    error={passwordError}
                                />

                            </Tooltip>
                            <br />
                            <Typography color="textSecondary" gutterBottom>
                                Tip: hover over the input fields if you forgot the username/password!
                            </Typography>
                            <br />
                            <Button variant="contained" type="submit" color="primary" id="login" name="login">Login</Button>
                        </form>
                    )}

                </LoginContextConsumer>
                <br />
            </Card>

        </div>
    );
}
export default withRouter(Login)