import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';


//import contextconsumer
import { LoginContextConsumer } from '../context/LoginContext.js';


const useStyles = makeStyles({
    title: {
        fontSize: 28,
        bold: true,
        color: "#000"
    }
});

export default function Header() {
    const classes = useStyles();

    const initialCount = localStorage.getItem("count") === null ? 0 : parseInt(localStorage.getItem("count"));
    const [count, setCount] = useState(initialCount);

    const initialAllApplicants = localStorage.getItem("allApplicants") === null ? "[]" : localStorage.getItem("allApplicants");
    const [allApplicants, setAllApplicants] = useState(initialAllApplicants);

    useEffect(() => {
        localStorage.setItem("count", 0);
        localStorage.setItem("allApplicants", allApplicants);
    }, [allApplicants, count]);



    function handleClick(event) {
        event.preventDefault();
        setCount(0);
        setAllApplicants("[]");
        window.location.href = "/jobs"
    }
    return (
        <div>
            <header className="App-header">
                <div className="divTitle">
                    <Typography component={Link} to="/" style={{ textDecoration: 'none' }} className={classes.title} color="textSecondary" gutterBottom>
                        RPA Exercise
                    </Typography>
                </div>
                <div className="divLogout">
                    <LoginContextConsumer>
                        {context => {
                            if (context.isLoggedIn.toString() === "true" || localStorage.getItem("isLoggedIn" === "true")) {
                                return (
                                    <div>
                                        <Button onClick={handleClick}>Reset Progress</Button>
                                        <Button onClick={() => (context.changeLoginStatus())}>Logout</Button>
                                    </div>
                                )
                            }
                        }}
                    </LoginContextConsumer>


                </div>
            </header>

        </div >
    )
}

