import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'

import Container from '@material-ui/core/Container';

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>


    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function Progress() {
    const classes = useStyles();
    const prog = calculatePercentage();
    const [progress, setProgress] = React.useState(prog);

    React.useEffect(() => {
        const prog = calculatePercentage();
        setProgress(prog)
        alert(prog)
    }, [progress]);
    // function handleClick() {
    //     alert(progress)
    //     setProgress(20);
    // }
    function calculatePercentage() {
        const count = localStorage.getItem("count");
        // const prog = count / 10 * 100 * 0.5;
        return count;
    }
    return (
        <div>
            <Container>
                <div className={classes.root}>
                    <LinearProgressWithLabel value={progress} />
                </div>
                {/* <Button onClick={handleClick}>Reset Progress</Button> */}
            </Container>
        </div>
    );
}