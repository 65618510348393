import { createTheme } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';

const theme = createTheme({
    typography: {
        fontFamily: ['"Josefin Sans"', 'Open Sans'].join(','),
        button: {
            textTransform: 'none'
        },
    },
    toggle: {
      thumbOnColor: 'yellow',
      trackOnColor: 'red'
    },
    palette: {
        primary: {
          light: '#F8DFBA',
          main: '#F1B66E',
          dark: '#EA9C50',
          contrastText: '#000',
        },
        secondary: {
          light: '#F5CFD0',
          main: '#D17A71',
          dark: '#D8553A',
          contrastText: '#000',
        },
      },
})

export default theme;