const applicantsData = [
    {
        applicantId: 1,
        jobId: 1,
        fullName: "Abigail Chew",
        email: "abigaic@email.com",
        mobile: "76557358",
        address: "3 Sungei Gedong Field",
        educationLevel: "Diploma",
        preScreeningScore: 90,
        resumeAttachment: ""
    },
    {
        applicantId: 2,
        jobId: 1,
        fullName: "Constance Li",
        email: "constanceli@email.com",
        mobile: "71538304",
        address: "Blk 316 Bedok Street 99 #04-30",
        educationLevel: "Degree",
        preScreeningScore: 70,
        resumeAttachment: ""
    },
    {
        applicantId: 3,
        jobId: 1,
        fullName: "How Yi Xi",
        email: "howyixi@email.com",
        mobile: "71400160",
        address: "97 Bukit Batok Crescent #07-7014",
        educationLevel: "Diploma",
        preScreeningScore: 65,
        resumeAttachment: ""
    },
    {
        applicantId: 4,
        jobId: 1,
        fullName: "Mary Teo",
        email: "maryt@email.com",
        mobile: "79339726",
        address: "524 Shenton Way #24-061 ",
        educationLevel: "Diploma",
        preScreeningScore: 80,
        resumeAttachment: ""
    },
    {
        applicantId: 5,
        jobId: 1,
        fullName: "Claire Liu",
        email: "clarieliu@email.com",
        mobile: "74717654",
        address: "Lor 70 Geylang #03-011",
        educationLevel: "Degree",
        preScreeningScore: 55,
        resumeAttachment: ""
    },
    {
        applicantId: 6,
        jobId: 1,
        fullName: "Loh Shayna",
        email: "shaynaloh@email.com",
        mobile: "72754727",
        address: "345 Ubi Road",
        educationLevel: "Diploma",
        preScreeningScore: 80,
        resumeAttachment: ""
    },
    {
        applicantId: 7,
        jobId: 1,
        fullName: "Fanny Lim Zi Qing",
        email: "fannylzq@email.com",
        mobile: "70161805",
        address: "Ang Mo Kio Industrial Park 5 #04-222",
        educationLevel: "Diploma",
        preScreeningScore: 70,
        resumeAttachment: ""
    },
    {
        applicantId: 8,
        jobId: 1,
        fullName: "Jack Wellington",
        email: "jwellington@email.com",
        mobile: "71019860",
        address: "991 Tras Street",
        educationLevel: "Diploma",
        preScreeningScore: 75,
        resumeAttachment: ""
    },
    {
        applicantId: 9,
        jobId: 2,
        fullName: "Jade Tan",
        email: "jadetan@email.com",
        mobile: "72525508",
        address: "105 Monks Hill Terrace",
        educationLevel: "Degree",
        preScreeningScore: 85,
        resumeAttachment: ""
    },
    {
        applicantId: 10,
        jobId: 2,
        fullName: "Yulia Poh",
        email: "yuliapoh@email.com",
        mobile: "71584558",
        address: "111G Rangoon Street",
        educationLevel: "Degree",
        preScreeningScore: 100,
        resumeAttachment: ""
    },
    {
        applicantId: 11,
        jobId: 2,
        fullName: "Lee Xuan Xuan",
        email: "leexuanxuan@email.com",
        mobile: "70579461",
        address: "Choa Chu Kang Avenue 70 #01-001",
        educationLevel: "Diploma",
        preScreeningScore: 40,
        resumeAttachment: ""
    },
    {
        applicantId: 12,
        jobId: 2,
        fullName: "Minnie Soh",
        email: "minniesoh@email.com",
        mobile: "79654058",
        address: "900 Tanjong Pagar Road",
        educationLevel: "Degree",
        preScreeningScore: 0,
        resumeAttachment: ""
    },
    {
        applicantId: 13,
        jobId: 2,
        fullName: "Kiki Sun",
        email: "kikisun@email.com",
        mobile: "79095595",
        address: "1 Madras Road",
        educationLevel: "Degree",
        preScreeningScore: 70,
        resumeAttachment: ""
    },
    {
        applicantId: 14,
        jobId: 2,
        fullName: "May Lin",
        email: "maylin@email.com",
        mobile: "72955162",
        address: "823A Tampines Street 81 #30-724",
        educationLevel: "Degree",
        preScreeningScore: 85,
        resumeAttachment: ""
    },
    {
        applicantId: 15,
        jobId: 2,
        fullName: "Elward Wong",
        email: "elwardwong@email.com",
        mobile: "71141392",
        address: "57 East Coast Park Avenue",
        educationLevel: "Degree",
        preScreeningScore: 50,
        resumeAttachment: ""
    },
    {
        applicantId: 16,
        jobId: 2,
        fullName: "Qin Li",
        email: "qinli@email.com",
        mobile: "75004181",
        address: "Sims Drive Lorong 173 #04-22",
        educationLevel: "Degree",
        preScreeningScore: 90,
        resumeAttachment: ""
    },
    {
        applicantId: 17,
        jobId: 3,
        fullName: "Olivia Foo",
        email: "oliviafoo@email.com",
        mobile: "72685806",
        address: "Admiralty Street 120 #17-10",
        educationLevel: "Degree",
        preScreeningScore: 30,
        resumeAttachment: ""
    },
    {
        applicantId: 18,
        jobId: 3,
        fullName: "Nick Oh",
        email: "nickoh@email.com",
        mobile: "77466009",
        address: "444 Ubi Avenue 2",
        educationLevel: "Diploma",
        preScreeningScore: 60,
        resumeAttachment: ""
    },
    {
        applicantId: 19,
        jobId: 3,
        fullName: "Sara Yeo",
        email: "sarayeo@email.com",
        mobile: "79504413",
        address: "Blk 50 Marine Parade Central #27-10",
        educationLevel: "Diploma",
        preScreeningScore: 80,
        resumeAttachment: ""
    },
    {
        applicantId: 20,
        jobId: 3,
        fullName: "Dilmah Johnson",
        email: "dilmahjohnson@email.com",
        mobile: "72851555",
        address: "190 Middle Road #41-70",
        educationLevel: "Degree",
        preScreeningScore: 90,
        resumeAttachment: ""
    },
    {
        applicantId: 21,
        jobId: 3,
        fullName: "Jude Ho",
        email: "judeho@email.com",
        mobile: "76949444",
        address: "3 Robinson Road #29-1350",
        educationLevel: "Diploma",
        preScreeningScore: 65,
        resumeAttachment: ""
    }
]
export default applicantsData