import './App.css';

//import components
import Landing from './components/Landing'
import Header from './components/Header';
import Footer from './components/Footer'

import Progress from './components/Progress';
import Login from './components/Login';
import JobsList from './components/JobsList';
import JobDetails from './components/JobDetails';
import Congratulations from './components/Congratulations';

import Container from '@material-ui/core/Container';


//import react-router
import { Switch, Route, Redirect } from 'react-router-dom';
//
import { LoginContextConsumer } from './context/LoginContext';

function App() {

  return (
    <div>

      <div className="App">
        {/* //   <header className="App-header">
        
    //   </header> */}

        <Header />
        <br />
        {/* <Progress></Progress> */}
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/jobs">
            <LoginContextConsumer>
              {context => {
                if (context.isLoggedIn.toString() === "true" || localStorage.getItem("isLoggedIn" === "true")) {
                  return (<JobsList />)
                } else return <Redirect to="/login" />
              }}
            </LoginContextConsumer>
          </Route>
          <Route path="/jobs/:jobId">
            <LoginContextConsumer>
              {context => {
                if (context.isLoggedIn.toString() === "true" || localStorage.getItem("isLoggedIn" === "true")) {
                  return (<JobDetails />)
                } else return <Redirect to="/login" />
              }}

            </LoginContextConsumer>
          </Route>
          <Route exact path="/congratulations">
            <Congratulations />
          </Route>
        </Switch>
      </div>
      {/*       
      <Footer className="footer" /> */}
    </div>
  );
}

export default App;
